import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { Menu } from "@blueprintjs/core";
import { MenuItem2 } from "@blueprintjs/popover2";
import { useNavigate } from "react-router-dom";

import Toolbar from "@material-ui/core/Toolbar";
import { Container, Typography } from "@material-ui/core";

import { MenuDivider, Button } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import logo from "../../assets/images/logo-white-bg.png";
import { useSelector } from "react-redux";

function HeaderLayout() {
  const countries = useSelector((state) => state.country);

  let navigate = useNavigate();
  const appsMenu = (
    <Menu>
      <MenuItem2
        icon="home"
        text="Home"
        onClick={() => {
          navigate("/");
        }}
      />

<MenuItem2
        icon="person"
        text="About"
        onClick={() => {
          navigate("/about");
        }}
      />

<MenuItem2
        icon="box"
        text="Sand Box"
        onClick={() => {
          navigate("/sandbox");
        }}
      />

<MenuItem2
        icon="comment"
        text="Contact"
        onClick={() => {
          navigate("/contact");
        }}
      />

    
      <MenuDivider />
    </Menu>
  );

  return (
    <React.Fragment>
      <AppBar position="sticky" className="appBarHeader" color="default">
        <Toolbar className="toolBarHeader">
          <Container sx={{ maxWidth: "md" }}>
            <nav className="bp4-navbar bp4-dark {{.modifier}} headerNav">
              <div className="bp4-navbar-group bp4-align-left logoTop">
                <div
                  className="bp4-navbar-heading"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {" "}
                  <img src={logo} alt="Joel Soto Logo" />
                  
                </div>
                {/* <div className="logo-text">JoelSoto.com</div> */}
              </div>
              <div className="bp4-navbar-group bp4-align-right">
                <Popover2
                  content={appsMenu}
                  fill={true}
                  placement="bottom"
                  className="popOverMenuHeader"
                  inheritDarkTheme={false}
                >
                  <Button
                    className="menuButton"
                    alignText="left"
                    fill={true}
                    icon="applications"
                    rightIcon="caret-down"
                    text="Menu"
                  />
                </Popover2>
                {/* <span className="bp4-navbar-divider"></span> */}
                {/* <button className="bp4-button bp4-minimal bp4-icon-user"></button>
                <button className="bp4-button bp4-minimal bp4-icon-cog"></button> */}
              </div>
            </nav>
          </Container>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default HeaderLayout;
