import React from "react";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "../assets/styles/custom.css";
import { Container, Typography } from "@material-ui/core";
import { Card, Button, H5 } from "@blueprintjs/core";
import { BreadcrumbProps, Icon } from "@blueprintjs/core";
import { Breadcrumbs2 } from "@blueprintjs/popover2";
import { Tab, Tabs } from "@blueprintjs/core";
import { useState } from "react";
import ForceDirected from "../components/ForceDirected";
import MapView from "../components/WorldChart";
import WorldChart from "../components/WorldChart";
import GridView from "../components/GridView";

function SandBoxPage() {
  const BREADCRUMBS = [
    { href: "/", text: "Home" },
    { href: "/sandbox", text: "Sandbox" },
  ];

  const [tabIndexSelected, setTabIndexSelected] = useState("ph");

  const handleNavbarTabChange = (tab) => {
    setTabIndexSelected(tab);
  };

  //   const renderCurrentBreadcrumb = ()=> ({ text, ...restProps }) => {
  //     // customize rendering of last breadcrumb
  //     return <Breadcrumb {...restProps}>{text} <Icon icon="star" /></Breadcrumb>;
  // };

  return (
    <React.Fragment>
      <Container sx={{ maxWidth: "md" }}>
        <Breadcrumbs2 className="breadcrumbs" items={BREADCRUMBS} />
        <Card
          disabled
          className="joel-logo"
          sx={{ maxWidth: "md" }}
          align="center"
        >
          <H5>
            <a href="#">Sandbox</a>
          </H5>
          <p className="paragraph-text">
            Hello there! Welcome to my digital Sandbox. This space is a
            collection of tools and features I've been playing with, including
            maps and data grids, all organized within easy-to-navigate tabs. You
            can see the different libraries that were used in the About section
            of this Webpage. While I've put effort into ensuring each widget is
            both functional and user-friendly, I'm always open to feedback and
            improvements. Feel free to explore the tabs and see the different
            components in action. Enjoy your exploration!
          </p>

          <Tabs
            id="TabsExample"
            selectedTabId={tabIndexSelected}
            renderActiveTabPanelOnly={true}
            onChange={handleNavbarTabChange}
            fill={true}
          >
            <Tab
              id="ph"
              title="Force Directed Graph"
              panel={<ForceDirected />}
            />

            <Tab id="amv5" title="World Map" panel={<WorldChart />} />

            <Tab id="amv4" title="Data Grid" panel={<GridView />} />

            <Tabs.Expander />
          </Tabs>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default SandBoxPage;
