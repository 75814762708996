import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";

class ForceDirected extends Component {

  constructor(props) {
    super(props);
   
  }

  componentDidMount() {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(
      "chartdivV4",
      am4plugins_forceDirected.ForceDirectedTree
    );
    
    chart.contentAlign = "top";
    chart.align = "center";
    chart.maxLevels = 1;
    chart.fontSize = 10;
    this.chart = chart;

    let container = chart.createChild(am4core.Container);
    
   

    let series = chart.series.push(
      new am4plugins_forceDirected.ForceDirectedSeries()
    );
    series.data = [{
      "name": "First",
      "children": [{
        "name": "A1", "value": 100
      }, {
        "name": "A2", "value": 60
      }, {
        "name": "A3", "value": 30
      }]
    }, {
      "name": "Second",
      "children": [{
        "name": "B1", "value": 135
      }, {
        "name": "B2", "value": 98
      }, {
        "name": "B3", "value": 56
      }]
    }, {
      "name": "Third",
      "children": [{
        "name": "C1", "value": 335
      }, {
        "name": "C2", "value": 148
      }, {
        "name": "C3", "value": 126
      }, {
        "name": "C4", "value": 26
      }]
    }, {
      "name": "Fourth",
      "children": [{
        "name": "D1", "value": 415
      }, {
        "name": "D2", "value": 148
      }, {
        "name": "D3", "value": 89
      }, {
        "name": "D4", "value": 64
      }, {
        "name": "D5", "value": 16
      }]
    }, {
      "name": "Fifth",
      "children": [{
        "name": "E1", "value": 687
      }, {
        "name": "E2", "value": 148
      }]
    }];
    series.fontSize = 10;
    series.maxRadius = am4core.percent(10);
    series.minRadius = am4core.percent(2);
    series.maxLevels = 1;

    series.dataFields.value = "value";
    series.dataFields.name = "name";
    series.dataFields.children = "children";


    series.nodes.template.expandAll = false;
    series.nodes.template.tooltipText = "{name}";
    series.nodes.template.fillOpacity = 1;
    series.nodes.template.label.text = "{name}";
    series.nodes.template.label.hideOversized = true;
    series.nodes.template.label.truncate = true;
    series.nodes.template.label.maxWidth = 200;
    series.nodes.template.label.wrap = true;
    series.nodes.template.draggable = true;

    series.links.template.distance = 2.2;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id="chartdivV4" className="amchart-custom"></div>;
  }
}

export default ForceDirected;