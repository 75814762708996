import React from "react";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "../assets/styles/custom.css";
import { Container, Typography } from "@material-ui/core";
import { Card, Button, H5 } from "@blueprintjs/core";
import { BreadcrumbProps, Icon } from "@blueprintjs/core";
import { Breadcrumbs2 } from "@blueprintjs/popover2";
import GridViewServer from "../components/GridViewServer";
import { Tab, Tabs } from "@blueprintjs/core";
import { useState } from "react";
import GridViewUI from "../components/GridViewUI";

function AboutPage() {

  const BREADCRUMBS = [
    { href: "/", text: "Home" },
    { href: "/about", text: "About" },
  ];

  const [tabIndexSelected, setTabIndexSelected] = useState();
  const [counter, setCounter] = useState(0);

  const handleNavbarTabChange = (tab) => {
    setTabIndexSelected(tab);
  };

  return (
    <React.Fragment>
      <Container sx={{ maxWidth: "md" }}>
        <Breadcrumbs2 className="breadcrumbs" items={BREADCRUMBS} />

        <Card
          disabled
          className="joel-logo"
          sx={{ maxWidth: "md" }}
          align="center"
        >
          <H5>
            <a href="#">About</a>
          </H5>

          <p className="paragraph-text">Greetings! I'm Joel :)</p>
          <p className="paragraph-text">
          This space is my digital playground where I tinker, evolve, and display my technical skills. 
          Below, you'll find details about the current setup, encompassing both server and UI components. At its core, it's an AWS instance powered by Apache, complemented by a React App. As I'm constantly updating and refining, bear with me if you spot any glitches. Dive into the Sandbox section for a closer look!   
          </p>

          <Tabs
            id="TabsExample"
            selectedTabId={tabIndexSelected}
            renderActiveTabPanelOnly={false}
            onChange={handleNavbarTabChange}
            fill={true}
          >
            <Tab id="ph" title="Server" panel={<GridViewServer />} />

            <Tab id="ui" title="UI" panel={<GridViewUI />} />

            <Tabs.Expander />
          </Tabs>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default AboutPage;
