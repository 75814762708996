import React from "react";
import { Container, Typography } from "@material-ui/core";
import logocomodo from "../../assets/images/sectigo_trust_seal_sm_82x32.png";

function FooterLayout() {
  return (
    <React.Fragment>
      <footer className="MuiPaper-elevation4 footerDiv">
        <nav
          background="#12395b"
          className="bp4-navbar bp4-dark {{.modifier}} footerNav"
        >
          <Container
            className="footer-stars"
            sx={{ maxWidth: "md" }}
            align="center"
          >
            <Typography>JoelSoto.com</Typography> 
          </Container>
        </nav>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

export default FooterLayout;
