import React from "react";

import { Route, Routes } from "react-router-dom";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import HomePage from "./pages/HomePage";
import HeaderLayout from "./pages/layout/HeaderLayout";
import FooterLayout from "./pages/layout/FooterLayout";

import { useDispatch } from 'react-redux';

import { setUpCountries } from "./store/slices/countrySlice";
import AboutPage from "./pages/AboutPage";
import SandBoxPage from "./pages/SandBoxPage";
import ContactPage from "./pages/ContactPage";
import MapView from "./components/WorldChart";
import WorldChart from "./components/WorldChart";






function App() {
  const dispatch = useDispatch();
  // dispatch(setUpCountries(COUNTRIES));

  return (
    <React.Fragment>
   
        <HeaderLayout />

        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/sandbox" element={<SandBoxPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/map" element={<WorldChart />} />
      
          </Routes>
        </main>

        <FooterLayout />

    </React.Fragment>
  );
}

export default App;
