import { createSlice } from '@reduxjs/toolkit';

const countrySlice = createSlice({
  name: 'countries',
  initialState: {
    countries: [],
  },
  reducers: {
    setUpCountries: (state, action) => {
   
        return [...action.payload]
    }
  },
});

export const { setUpCountries } = countrySlice.actions;

export default countrySlice.reducer;