import React, { Component } from "react";




import JqxGrid, { IGridProps, jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

import { Box, Container } from "@material-ui/core";
import GridLayout from "react-grid-layout";

class GridViewServer extends Component {
  jqx_grid = React.createRef();

  constructor(props) {
    super(props);

    const data = [
      {
        name: "Operating System",
        description: "Centos",
      },
      {
        name: "Web Server",
        description: "Apache",
      },
      {
        name: "Instance Type",
        description: "EC2",
      },
      {
        name: "AMI",
        description: "ami-0a73cf4494e3844a3",
      },{
        name: "Instance Size",
        description: "t.nano",
      },
      {
        name: "Certificate Authority / SSL",
        description: "https://www.comodo.com/",
      },
      {
        name: "Registar/Hosting",
        description: "Amazon / EC2",
      },
      {
        name: "DNS",
        description: "Route53",
      },
     


      
    ];

    const source = {
      datatype: "json",
      dataFields: [
        { name: "name", type: "string" },
        { name: "description", type: "string" },
      ],

      localData: data,
    };

    const dataAdapter = new jqx.dataAdapter(source);

    this.state = {
      isGridExpanded: false,
      defaultChecked: true,
      columns: [
        { dataField: "name", text: "Name", width: "30%" },
        { dataField: "description", text: "Description", width: "50%" },
      ],
      columnCollapseIsOpen: false,
      source: dataAdapter,
    };
  }

  render() {
    const layout = [{ i: "a", x: 0, y: 0, w: 10, h: 90 }];

    return (
      // <Container>
      //   <GridLayout
      //     isDraggable={false}
      //     className="layout"
      //     layout={layout}
      //     cols={12}
      //     rowHeight={30}
  
      //   >
          <div key="a">
            <Container>
              <Box className="gridView">
                <JqxGrid
                
                  ref={this.jqx_grid}
                  sortable={true}
                  enablebrowserselection={true}
                  filterable={true}
                  filterMode={"simple"}
                  theme={"Fluent"}
                  width={"100%"}
                  height={"100%"}
                  columnsresize={true}
                  columnsreorder={true}
                  scrollBarSize={15}
                  source={this.state.source}
                  columns={this.state.columns}
                  autoheight={false}
                  altrows={true}
                  enabletooltips={true}
                  editable={false}
                  pageable={true}
                  pagerMode={"simple"}
                  pagesizeoptions={["5", "10", "20", "50", "100"]}
                />
              </Box>
            </Container>
          </div>
      //   </GridLayout>
      // </Container>
    );
  }
}

export default GridViewServer;
