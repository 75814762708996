import React from "react";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "../assets/styles/custom.css";
import { Container, Typography } from "@material-ui/core";
import { Card, Button,H5 } from "@blueprintjs/core";
import { BreadcrumbProps, Icon } from "@blueprintjs/core";
import { Breadcrumbs2 } from "@blueprintjs/popover2";

function ContactPage() {


  const BREADCRUMBS= [
    { href: "/", text: "Home" },
    { href: "/contact", text: "Contact Us" },
    
];

//   const renderCurrentBreadcrumb = ()=> ({ text, ...restProps }) => {
//     // customize rendering of last breadcrumb
//     return <Breadcrumb {...restProps}>{text} <Icon icon="star" /></Breadcrumb>;
// };

  return (
    <React.Fragment>
      <Container sx={{ maxWidth: "md" }}>
      <Breadcrumbs2
             className="breadcrumbs"
                items={BREADCRUMBS}
             />
      <Card
        disabled
        className="joel-logo"
        sx={{ maxWidth: "md" }}
        align="center"
      >
        <H5>
          <a href="#">Contact Us</a>
        </H5>

        <Card
        disabled
        className="contact-email"
        width={30}
        sx={{ maxWidth: "sm" }}
        elevation={4}
        align="center"
      >

<H5>
          <a href="#">Email</a>
        </H5>
        <Icon icon="envelope" size={50} />
<p >
        joel.soto@gmail.com
        </p>
      </Card>


        
        

        
      </Card>
      </Container>
    </React.Fragment>
  );
}

export default ContactPage;
