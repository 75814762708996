import React from "react";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "../assets/styles/custom.css";
import { useEffect, useRef } from "react";
import { Card,  Toaster,
  Position,
  Intent, } from "@blueprintjs/core";




function HomePage() {
  const toasterRef = useRef(null);

  useEffect(() => {
    const toast = async () => {
      toasterRef.current.show({ message: "This page is under construction.  Feel free to look around, Im adding something new every day.", intent: Intent.PRIMARY,timeout:0});
    };

    toast(); 
  }, []);


  return (
    <React.Fragment>
         <Toaster
          position={Position.TOP}
          ref={toasterRef}
          intent={Intent.PRIMARY}
        ></Toaster>
      <Card
        disabled
        className="joel-logo"
        sx={{ maxWidth: "md" }}
        align="center"
      >
        <div className="bp4-align-center homepage-background"></div>
        <div className="homepage-slogan">
          Integrity Honor Mission
        </div>
      </Card>
    </React.Fragment>
  );
}

export default HomePage;
